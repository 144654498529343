import React from "react";
import ReactMarkdown from "react-markdown";
import { useGetAboutQuery } from "../../../api";

const markdownStyles = `
  .markdown-content {
    font-family: Arial, sans-serif;
    line-height: 1.7;
    color: #ffffff;
    font-size: 29px;
    font-weight: 400
  }
  .markdown-content h1, .markdown-content h2, .markdown-content h3, 
  .markdown-content h4, .markdown-content h5, .markdown-content h6 {
    margin-top: 32px;
    margin-bottom: 20px;
    font-weight: 300;
    line-height: 1.25;
  }
  .markdown-content h1 { font-size: 2.5em; }
  .markdown-content h2 { font-size: 2.25em; }   
  .markdown-content h3 { font-size: 2.1em; } 
  .markdown-content h4 { font-size: 2em; }
  .markdown-content h5 { font-size: 1.75em; }
  .markdown-content h6 { font-size: 1.5em; }  
  .markdown-content p, .markdown-content ul, .markdown-content ol {
    margin-bottom: 20px;
  }
  .markdown-content a {
    color: #0366d6;
    text-decoration: none;
  }
  .markdown-content a:hover {
    text-decoration: underline;
  }
  .markdown-content code {
    background-color: rgba(27,31,35,0.05);
    border-radius: 3px;
    font-size: 90%;
    margin: 0;
    padding: 0.2em 0.4em;
  }
  .markdown-content pre {
    background-color: #ffffff;
    border-radius: 3px;
    font-size: 90%;
    line-height: 1.45;
    overflow: auto;
    padding: 20px;
  }
  .markdown-content ul, .markdown-content ol {
    padding-left: 30px;
  }
  .markdown-content li {
    margin-bottom: 10px;
  }
`;

const MobileAboutContent = () => {
  const { data: about, isLoading, isError } = useGetAboutQuery();

  if (isLoading) {
    return <div>Loading about section...</div>;
  }

  if (isError) {
    return <div>Error loading about section</div>;
  }

  if (!about || !about.content) {
    return <div>No about information available</div>;
  }

  return (
    <div className="about-section">
      <style>{markdownStyles}</style>
      <div className="markdown-content">
        <ReactMarkdown>{about.content}</ReactMarkdown>
      </div>
    </div>
  );
};

export default MobileAboutContent;
