import React from "react";
import AdminSidebar from "./AdminSidebar";
import AdminContent from "./AdminContent";

const AdminPage = () => {
  return (
    <div className="flex h-screen bg-gray-950 text-gray-200">
      <AdminSidebar />
      <AdminContent />
    </div>
  );
};

export default AdminPage;
