import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActiveEducation } from "../../slices/adminSlice";
import {
  useGetEducationsQuery,
  useAddEducationMutation,
  useUpdateEducationMutation,
  useDeleteEducationMutation,
} from "../../api";

const AdminEducation = () => {
  const dispatch = useDispatch();
  const activeEducation = useSelector((state) => state.admin.activeEducation);
  const { data: educations, isLoading } = useGetEducationsQuery();
  const [addEducation] = useAddEducationMutation();
  const [updateEducation] = useUpdateEducationMutation();
  const [deleteEducation] = useDeleteEducationMutation();

  const [formData, setFormData] = useState({
    school: "",
    degree: "",
    fieldOfStudy: "",
    startDate: "",
    endDate: "",
    description: "",
    gpa: "",
    imageUrl: "",
  });

  const [imageFile, setImageFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      const file = files[0];
      setImageFile(file);
      setFormData((prev) => ({
        ...prev,
        imageUrl: file ? URL.createObjectURL(file) : "",
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSave = async () => {
    if (!formData.school.trim() || !formData.degree.trim()) {
      alert("School and Degree are required fields.");
      return;
    }

    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (key !== "imageUrl") {
          if (key === "gpa" && formData[key] === "") {
            formDataToSend.append(key, null);
          } else {
            formDataToSend.append(key, formData[key]);
          }
        }
      }

      if (imageFile) {
        formDataToSend.append("image", imageFile);
      }

      if (activeEducation) {
        await updateEducation({
          id: activeEducation.id,
          formData: formDataToSend,
        }).unwrap();
        alert("Education updated successfully!");
      } else {
        const result = await addEducation(formDataToSend).unwrap();
        setFormData((prev) => ({
          ...prev,
          imageUrl: result.imageUrl,
        }));
        alert("Education added successfully!");
        clearForm();
      }
    } catch (error) {
      console.error("Error saving education:", error);
      alert("Error saving education. Please try again.");
    }
  };

  const handleDelete = async () => {
    try {
      await deleteEducation(activeEducation.id);
      dispatch(setActiveEducation(null));
      alert("Education deleted successfully!");
      clearForm();
    } catch (error) {
      console.error("Error deleting education:", error);
      alert("Error deleting education. Please try again.");
    }
  };

  const clearForm = () => {
    setFormData({
      school: "",
      degree: "",
      fieldOfStudy: "",
      startDate: "",
      endDate: "",
      description: "",
      gpa: "",
      imageUrl: "",
    });
    setImageFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    dispatch(setActiveEducation(null));
  };

  const handleCancel = () => {
    clearForm();
  };

  useEffect(() => {
    if (activeEducation) {
      setFormData({
        ...activeEducation,
        gpa: activeEducation.gpa ?? "",
      });
      setImageFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else {
      clearForm();
    }
  }, [activeEducation]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="max-w-xl mx-auto p-6 bg-gray-800 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-white mb-4">
        {activeEducation ? "Edit " : "New "}Education
      </h2>
      <input
        type="text"
        name="school"
        value={formData.school}
        onChange={handleInputChange}
        placeholder="School (required)"
        className="w-full p-2 mb-4 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white"
        required
      />
      <input
        type="text"
        name="degree"
        value={formData.degree}
        onChange={handleInputChange}
        placeholder="Degree (required)"
        className="w-full p-2 mb-4 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white"
        required
      />
      <input
        type="text"
        name="fieldOfStudy"
        value={formData.fieldOfStudy}
        onChange={handleInputChange}
        placeholder="Field of Study"
        className="w-full p-2 mb-4 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white"
      />
      <div className="flex space-x-4 mb-4">
        <input
          type="date"
          name="startDate"
          value={formData.startDate}
          onChange={handleInputChange}
          className="w-1/2 p-2 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white"
        />
        <input
          type="date"
          name="endDate"
          value={formData.endDate}
          onChange={handleInputChange}
          className="w-1/2 p-2 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white"
        />
      </div>
      <textarea
        name="description"
        value={formData.description}
        onChange={handleInputChange}
        placeholder="Description"
        className="w-full p-2 mb-4 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white h-32"
      />
      <input
        type="number"
        name="gpa"
        value={formData.gpa}
        onChange={handleInputChange}
        placeholder="GPA (optional)"
        step="0.1"
        min="0"
        max="4"
        className="w-full p-2 mb-4 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white"
      />
      <div className="mb-4 flex items-start">
        <div className="flex-grow mr-4">
          <label className="block text-white mb-2">
            School Logo (optional)
          </label>
          <input
            type="file"
            name="image"
            onChange={handleInputChange}
            accept="image/*"
            className="w-full p-2 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white"
            ref={fileInputRef}
          />
        </div>
        {formData.imageUrl && (
          <img
            src={formData.imageUrl}
            alt="School logo preview"
            className="w-20 h-20 object-cover rounded"
          />
        )}
      </div>
      <div className="flex justify-between">
        {activeEducation && (
          <>
            <button
              onClick={handleDelete}
              className="w-1/3 bg-red-600 hover:bg-red-700 text-white py-2 rounded transition duration-200 mr-2"
            >
              Delete
            </button>
            <button
              onClick={handleCancel}
              className="w-1/3 bg-gray-600 hover:bg-gray-700 text-white py-2 rounded transition duration-200 mr-2"
            >
              Cancel
            </button>
          </>
        )}
        <button
          onClick={handleSave}
          className={`${
            activeEducation ? "w-1/3" : "w-full"
          } bg-blue-600 hover:bg-blue-700 text-white py-2 rounded transition duration-200`}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AdminEducation;
