import React, { useEffect, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { BoxHelper } from "three";
import { useSelector } from "react-redux";

function DesktopModel() {
  const { scene } = useGLTF("/models/macbook_pro_2021.glb");
  const modelRef = useRef();
  const isMobile = useSelector((state) => state.mobile.isMobile);
  const currentInitialState = useSelector(
    (state) => state.camera.currentInitialState
  );

  useEffect(() => {
    if (modelRef.current && !isMobile && currentInitialState) {
      const box = new BoxHelper(modelRef.current, 0xffff00);
      if (box.geometry.boundingSphere) {
        const center = box.geometry.boundingSphere.center;
        modelRef.current.position.sub(center);
      }

      modelRef.current.scale.set(
        currentInitialState.modelScale,
        currentInitialState.modelScale,
        currentInitialState.modelScale
      );
      modelRef.current.position.set(
        currentInitialState.modelPosition.x,
        currentInitialState.modelPosition.y,
        currentInitialState.modelPosition.z
      );
      modelRef.current.rotation.y = Math.PI / 2;
    }
  }, [isMobile, currentInitialState]);

  return !isMobile ? <primitive ref={modelRef} object={scene} /> : null;
}

export default DesktopModel;
