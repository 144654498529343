import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActiveWorkExperience } from "../../slices/adminSlice";
import {
  useAddWorkExperienceMutation,
  useUpdateWorkExperienceMutation,
  useDeleteWorkExperienceMutation,
} from "../../api";

const AdminWorkExperience = () => {
  const dispatch = useDispatch();
  const activeWorkExperience = useSelector(
    (state) => state.admin.activeWorkExperience
  );
  const [addWorkExperience] = useAddWorkExperienceMutation();
  const [updateWorkExperience] = useUpdateWorkExperienceMutation();
  const [deleteWorkExperience] = useDeleteWorkExperienceMutation();

  const [formData, setFormData] = useState({
    company: "",
    position: "",
    startDate: "",
    endDate: "",
    description: "",
    isCurrent: false,
    imageUrl: "",
  });

  const [imageFile, setImageFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === "file") {
      const file = files[0];
      setImageFile(file);
      setFormData((prev) => ({
        ...prev,
        imageUrl: file ? URL.createObjectURL(file) : "",
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSave = async () => {
    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (key !== "imageUrl") {
          formDataToSend.append(key, formData[key]);
        }
      }

      if (imageFile) {
        formDataToSend.append("image", imageFile);
      }

      if (activeWorkExperience) {
        await updateWorkExperience({
          id: activeWorkExperience.id,
          formData: formDataToSend,
        }).unwrap();
        alert("Work experience updated successfully!");
      } else {
        const result = await addWorkExperience(formDataToSend).unwrap();
        setFormData((prev) => ({
          ...prev,
          imageUrl: result.imageUrl,
        }));
        alert("Work experience added successfully!");
        clearForm();
      }
    } catch (error) {
      console.error("Error saving work experience:", error);
      alert("Error saving work experience. Please try again.");
    }
  };

  const handleDelete = async () => {
    try {
      await deleteWorkExperience(activeWorkExperience.id);
      dispatch(setActiveWorkExperience(null));
      alert("Work experience deleted successfully!");
      clearForm();
    } catch (error) {
      console.error("Error deleting work experience:", error);
      alert("Error deleting work experience. Please try again.");
    }
  };

  const clearForm = useCallback(() => {
    setFormData({
      company: "",
      position: "",
      startDate: "",
      endDate: "",
      description: "",
      isCurrent: false,
      imageUrl: "",
    });
    setImageFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    dispatch(setActiveWorkExperience(null));
  }, [dispatch, fileInputRef]);

  const handleCancel = () => {
    clearForm();
  };

  useEffect(() => {
    if (activeWorkExperience) {
      setFormData(activeWorkExperience);
      setImageFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else {
      clearForm();
    }
  }, [activeWorkExperience, clearForm]);

  return (
    <div className="max-w-xl mx-auto p-6 bg-gray-800 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-white mb-4">
        {activeWorkExperience ? "Edit " : "New "}Work Experience
      </h2>
      <input
        type="text"
        name="company"
        value={formData.company}
        onChange={handleInputChange}
        placeholder="Company"
        className="w-full p-2 mb-4 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white"
      />
      <input
        type="text"
        name="position"
        value={formData.position}
        onChange={handleInputChange}
        placeholder="Position"
        className="w-full p-2 mb-4 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white"
      />
      <label className="flex items-center mb-4 text-white">
        <input
          type="checkbox"
          name="isCurrent"
          checked={formData.isCurrent}
          onChange={handleInputChange}
          className="mr-2"
        />
        <span>Current Job</span>
      </label>
      <div className="flex space-x-4 mb-4">
        <input
          type="date"
          name="startDate"
          value={formData.startDate}
          onChange={handleInputChange}
          className="w-1/2 p-2 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white"
        />
        <input
          type="date"
          name="endDate"
          value={formData.endDate}
          onChange={handleInputChange}
          disabled={formData.isCurrent}
          className="w-1/2 p-2 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white disabled:bg-gray-600 disabled:text-gray-400"
        />
      </div>
      <textarea
        name="description"
        value={formData.description}
        onChange={handleInputChange}
        placeholder="Description"
        className="w-full p-2 mb-4 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white h-32"
      />
      <div className="mb-4 flex items-start">
        <div className="flex-grow mr-4">
          <label className="block text-white mb-2">
            Company Logo (optional)
          </label>
          <input
            type="file"
            name="image"
            onChange={handleInputChange}
            accept="image/*"
            className="w-full p-2 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white"
            ref={fileInputRef}
          />
        </div>
        {formData.imageUrl && (
          <img
            src={formData.imageUrl}
            alt="Company logo preview"
            className="w-20 h-20 object-cover rounded"
          />
        )}
      </div>
      <div className="flex justify-between">
        {activeWorkExperience && (
          <>
            <button
              onClick={handleDelete}
              className="w-1/3 bg-red-600 hover:bg-red-700 text-white py-2 rounded transition duration-200 mr-2"
            >
              Delete
            </button>
            <button
              onClick={handleCancel}
              className="w-1/3 bg-gray-600 hover:bg-gray-700 text-white py-2 rounded transition duration-200 mr-2"
            >
              Cancel
            </button>
          </>
        )}
        <button
          onClick={handleSave}
          className={`${
            activeWorkExperience ? "w-1/3" : "w-full"
          } bg-blue-600 hover:bg-blue-700 text-white py-2 rounded transition duration-200`}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AdminWorkExperience;
