import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSnapped: false,
  modelSnapped: false,
  cameraSnapped: false,
  mobileInitialState: {
    cameraPosition: { x: 0, y: 0, z: 40 },
    modelPosition: { x: -0.327, y: -17.66, z: -1.86 },
    modelScale: 0.4,
  },
  desktopInitialState: {
    cameraPosition: { x: 0, y: 0, z: 1.25 },
    modelPosition: { x: 0, y: -1, z: 0 },
    modelScale: 9,
  },
};

export const cameraSlice = createSlice({
  name: "camera",
  initialState,
  reducers: {
    setIsSnapped: (state, action) => {
      state.isSnapped = action.payload;
    },
    setModelSnapped: (state, action) => {
      state.modelSnapped = action.payload;
    },
    setCameraSnapped: (state, action) => {
      state.cameraSnapped = action.payload;
    },
    setInitialState: (state, action) => {
      const { isMobile } = action.payload;
      state.currentInitialState = isMobile
        ? state.mobileInitialState
        : state.desktopInitialState;
    },
  },
});

export const {
  setIsSnapped,
  setModelSnapped,
  setCameraSnapped,
  setInitialState,
} = cameraSlice.actions;

export default cameraSlice.reducer;
