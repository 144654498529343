import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminPage from "./components/admin/AdminPage";
import ThreeDSceneWrapper from "./components/ThreeDSceneWrapper";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/admin/Login";
import axios from "axios";

function App() {
  // track user visit
  useEffect(() => {
    const trackVisit = async () => {
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/visitors/track`);
      } catch (error) {
        console.error("Error tracking visit:", error);
      }
    };

    trackVisit();
  }, []);

  return (
    <Router>
      <div className="min-h-screen bg-black h-full text-gray-100">
        <main className="w-full h-screen">
          <Routes>
            <Route path="/" element={<ThreeDSceneWrapper />} />
            <Route path="/admin/login" element={<Login />} />
            <Route
              path="/admin/"
              element={
                <ProtectedRoute>
                  <AdminPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
