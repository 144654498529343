import { configureStore, combineReducers } from "@reduxjs/toolkit";
import mobileReducer from "./slices/mobileSlice";
import cameraReducer from "./slices/cameraSlice";
import adminReducer from "./slices/adminSlice";
import { api } from "./api";

const appReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  mobile: mobileReducer,
  camera: cameraReducer,
  admin: adminReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export default store;
