import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActiveProjectExperience } from "../../slices/adminSlice";
import {
  useGetProjectExperiencesQuery,
  useAddProjectExperienceMutation,
  useUpdateProjectExperienceMutation,
  useDeleteProjectExperienceMutation,
} from "../../api";

const AdminProjectExperience = () => {
  const dispatch = useDispatch();
  const activeProjectExperience = useSelector(
    (state) => state.admin.activeProjectExperience
  );
  const { data: projectExperiences, isLoading } =
    useGetProjectExperiencesQuery();
  const [addProjectExperience] = useAddProjectExperienceMutation();
  const [updateProjectExperience] = useUpdateProjectExperienceMutation();
  const [deleteProjectExperience] = useDeleteProjectExperienceMutation();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    technologies: [],
    startDate: "",
    endDate: "",
    link: "",
    imageUrl: "",
  });

  const [newTechnology, setNewTechnology] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      const file = files[0];
      setImageFile(file);
      setFormData((prev) => ({
        ...prev,
        imageUrl: file ? URL.createObjectURL(file) : "",
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleAddTechnology = () => {
    if (
      newTechnology.trim() !== "" &&
      !formData.technologies.includes(newTechnology.trim())
    ) {
      setFormData((prev) => ({
        ...prev,
        technologies: [...prev.technologies, newTechnology.trim()],
      }));
      setNewTechnology("");
    }
  };

  const handleRemoveTechnology = (tech) => {
    setFormData((prev) => ({
      ...prev,
      technologies: prev.technologies.filter((t) => t !== tech),
    }));
  };

  const handleSave = async () => {
    // Basic form validation
    if (!formData.title.trim()) {
      alert("Project title is required.");
      return;
    }

    try {
      const formDataToSend = new FormData();
      console.log("formData:", formData.technologies);
      for (const key in formData) {
        if (key === "technologies") {
          formDataToSend.append(key, formData[key].join(","));
        } else if (key !== "imageUrl") {
          formDataToSend.append(key, formData[key]);
        }
      }

      if (imageFile) {
        formDataToSend.append("image", imageFile);
      }

      if (activeProjectExperience) {
        await updateProjectExperience({
          id: activeProjectExperience.id,
          formData: formDataToSend,
        }).unwrap();
        alert("Project experience updated successfully!");
      } else {
        const result = await addProjectExperience(formDataToSend).unwrap();
        setFormData((prev) => ({
          ...prev,
          imageUrl: result.imageUrl,
        }));
        alert("Project experience added successfully!");
        clearForm();
      }
    } catch (error) {
      console.error("Error saving project experience:", error);
      alert("Error saving project experience. Please try again.");
    }
  };

  const handleDelete = async () => {
    try {
      await deleteProjectExperience(activeProjectExperience.id);
      dispatch(setActiveProjectExperience(null));
      alert("Project experience deleted successfully!");
      clearForm();
    } catch (error) {
      console.error("Error deleting project experience:", error);
      alert("Error deleting project experience. Please try again.");
    }
  };

  const clearForm = () => {
    setFormData({
      title: "",
      description: "",
      technologies: [],
      startDate: "",
      endDate: "",
      link: "",
      imageUrl: "",
    });
    setNewTechnology("");
    setImageFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    dispatch(setActiveProjectExperience(null));
  };

  const handleCancel = () => {
    clearForm();
  };

  useEffect(() => {
    if (activeProjectExperience) {
      setFormData({
        ...activeProjectExperience,
        technologies: Array.isArray(activeProjectExperience.technologies)
          ? activeProjectExperience.technologies
          : activeProjectExperience.technologies
          ? activeProjectExperience.technologies
              .split(",")
              .map((tech) => tech.trim())
          : [],
      });
      setImageFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else {
      clearForm();
    }
  }, [activeProjectExperience]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="max-w-xl mx-auto p-6 bg-gray-800 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-white mb-4">
        {activeProjectExperience ? "Edit " : "New "}Project Experience
      </h2>
      <input
        type="text"
        name="title"
        value={formData.title}
        onChange={handleInputChange}
        placeholder="Project Title (required)"
        className="w-full p-2 mb-4 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white"
        required
      />
      <textarea
        name="description"
        value={formData.description}
        onChange={handleInputChange}
        placeholder="Description"
        className="w-full p-2 mb-4 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white h-32"
      />
      <div className="mb-4">
        <label className="block text-white mb-2">Technologies</label>
        <div className="flex flex-wrap mb-2">
          {formData.technologies.map((tech, index) => (
            <span
              key={index}
              className="bg-blue-500 text-white px-2 py-1 rounded mr-2 mb-2 flex items-center"
            >
              {tech}
              <button
                onClick={() => handleRemoveTechnology(tech)}
                className="ml-2 text-xs bg-blue-600 rounded-full w-4 h-4 flex items-center justify-center"
              >
                ×
              </button>
            </span>
          ))}
        </div>
        <div className="flex">
          <input
            type="text"
            value={newTechnology}
            onChange={(e) => setNewTechnology(e.target.value)}
            placeholder="Add a technology"
            className="flex-grow p-2 border border-gray-600 rounded-l focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white"
          />
          <button
            onClick={handleAddTechnology}
            className="bg-blue-500 text-white px-4 rounded-r hover:bg-blue-600"
          >
            Add
          </button>
        </div>
      </div>
      <div className="flex space-x-4 mb-4">
        <input
          type="date"
          name="startDate"
          value={formData.startDate}
          onChange={handleInputChange}
          className="w-1/2 p-2 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white"
        />
        <input
          type="date"
          name="endDate"
          value={formData.endDate}
          onChange={handleInputChange}
          className="w-1/2 p-2 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white"
        />
      </div>
      <input
        type="text"
        name="link"
        value={formData.link}
        onChange={handleInputChange}
        placeholder="Project Link"
        className="w-full p-2 mb-4 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white"
      />
      <div className="mb-4 flex items-start">
        <div className="flex-grow mr-4">
          <label className="block text-white mb-2">Project Image</label>
          <input
            type="file"
            name="image"
            id="image"
            onChange={handleInputChange}
            accept="image/*"
            className="w-full p-2 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white "
            ref={fileInputRef}
          />
        </div>
        {formData.imageUrl && (
          <img
            src={formData.imageUrl}
            alt="Project preview"
            className="w-20 h-20 object-cover rounded"
          />
        )}
      </div>
      <div className="flex justify-between">
        {activeProjectExperience && (
          <>
            <button
              onClick={handleDelete}
              className="w-1/3 bg-red-600 hover:bg-red-700 text-white py-2 rounded transition duration-200 mr-2"
            >
              Delete
            </button>
            <button
              onClick={handleCancel}
              className="w-1/3 bg-gray-600 hover:bg-gray-700 text-white py-2 rounded transition duration-200 mr-2"
            >
              Cancel
            </button>
          </>
        )}
        <button
          onClick={handleSave}
          className={`${
            activeProjectExperience ? "w-1/3" : "w-full"
          } bg-blue-600 hover:bg-blue-700 text-white py-2 rounded transition duration-200`}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AdminProjectExperience;
