import React, { useEffect, useRef, useState } from "react";
import { useThree, useFrame } from "@react-three/fiber";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsSnapped,
  setCameraSnapped,
  setInitialState,
} from "../slices/cameraSlice";
import { OrbitControls } from "@react-three/drei";
import * as THREE from "three";

function CameraController() {
  const { camera, gl } = useThree();
  const controlsRef = useRef();
  const lastTap = useRef(0);
  const doubleTapDelay = 300;
  const isMobile = useSelector((state) => state.mobile.isMobile);
  const isSnapped = useSelector((state) => state.camera.isSnapped);
  const cameraSnapped = useSelector((state) => state.camera.cameraSnapped);
  const dispatch = useDispatch();

  const currentInitialState = useSelector(
    (state) => state.camera.currentInitialState
  );

  const initialCameraPosition = useRef(null);
  const [targetPosition, setTargetPosition] = useState(null);
  const [isMoving, setIsMoving] = useState(false);

  // Update initialCameraPosition when isMobile changes
  useEffect(() => {
    dispatch(setInitialState({ isMobile }));

    // Use default values if currentInitialState is not set yet
    const defaultPosition = isMobile
      ? { x: 0, y: 0, z: 40 }
      : { x: 0, y: 0, z: 1.25 };
    const cameraPosition =
      currentInitialState?.cameraPosition || defaultPosition;

    initialCameraPosition.current = new THREE.Vector3(
      cameraPosition.x,
      cameraPosition.y,
      cameraPosition.z
    );
    camera.position.copy(initialCameraPosition.current);
    camera.updateProjectionMatrix();
  }, [isMobile, camera, dispatch, currentInitialState]);

  const snapCameraToScreen = () => {
    const newIsSnapped = !isSnapped;
    setTargetPosition(initialCameraPosition.current.clone());
    setIsMoving(true);
    dispatch(setIsSnapped(newIsSnapped));
  };

  useFrame(() => {
    if (isMoving && targetPosition) {
      const currentPos = camera.position;
      const distance = currentPos.distanceTo(targetPosition);

      if (distance > 0.01) {
        camera.position.lerp(targetPosition, 0.1);
        camera.updateProjectionMatrix();
      } else {
        setIsMoving(false);
        setTargetPosition(null);
        dispatch(setCameraSnapped(isSnapped));
      }
    }
  });

  useEffect(() => {
    if (controlsRef.current) {
      const lockControls = cameraSnapped && !isMoving;
      controlsRef.current.enabled = !lockControls;
    }
  }, [cameraSnapped, isMoving]);

  useEffect(() => {
    const handleDoubleClick = () => {
      console.log("Double click");
      snapCameraToScreen();
    };

    const handleTouchEnd = (event) => {
      const currentTime = new Date().getTime();
      const tapLength = currentTime - lastTap.current;
      if (tapLength < doubleTapDelay && tapLength > 0) {
        snapCameraToScreen();
      }
      lastTap.current = currentTime;
    };

    const canvas = gl.domElement;
    if (isMobile) {
      canvas.addEventListener("touchend", handleTouchEnd);
    } else {
      canvas.addEventListener("dblclick", handleDoubleClick);
    }

    return () => {
      if (isMobile) {
        canvas.removeEventListener("touchend", handleTouchEnd);
      } else {
        canvas.removeEventListener("dblclick", handleDoubleClick);
      }
    };
  }, [camera, gl, doubleTapDelay, isMobile, isSnapped]);

  return <OrbitControls ref={controlsRef} />;
}

export default CameraController;
