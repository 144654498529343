import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveSection,
  setActiveAbout,
  setActiveEducation,
  setActiveWorkExperience,
  setActiveProjectExperience,
} from "../../slices/adminSlice";
import EducationSidebarCard from "./sidebar/EducationSidebarCard";
import WorkExperienceSidebarCard from "./sidebar/WorkExperienceSidebarCard";
import ProjectExperienceSidebarCard from "./sidebar/ProjectExperienceSidebarCard";
import { getCurrentUser, logout } from "../../api";
import { useNavigate } from "react-router-dom";

const AdminSidebar = () => {
  const dispatch = useDispatch();
  const activeSection = useSelector((state) => state.admin.activeSection);
  const [isResizingMain, setIsResizingMain] = useState(false);
  const [isResizingCards, setIsResizingCards] = useState(false);
  const [mainSidebarWidth, setMainSidebarWidth] = useState(225);
  const [cardsSidebarWidth, setCardsSidebarWidth] = useState(300);
  const mainSidebarRef = useRef(null);
  const cardsSidebarRef = useRef(null);
  const [isCardSidebarVisible, setIsCardSidebarVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const fetchUser = useCallback(async () => {
    try {
      const userData = await getCurrentUser();
      setUser(userData);
    } catch (error) {
      console.error("Failed to fetch user:", error);
      navigate("/admin/login");
    }
  }, [navigate]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isResizingMain) {
        const newWidth =
          e.clientX - mainSidebarRef.current.getBoundingClientRect().left;
        setMainSidebarWidth(Math.max(200, Math.min(400, newWidth)));
      } else if (isResizingCards) {
        const mainWidth = mainSidebarRef.current.getBoundingClientRect().width;
        const newWidth =
          e.clientX - cardsSidebarRef.current.getBoundingClientRect().left;
        setCardsSidebarWidth(Math.max(200, Math.min(400, newWidth)));
      }
    };

    const handleMouseUp = () => {
      setIsResizingMain(false);
      setIsResizingCards(false);
    };

    if (isResizingMain || isResizingCards) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizingMain, isResizingCards]);

  useEffect(() => {
    setIsAnimating(true);
    setIsCardSidebarVisible(activeSection !== "about");
    if (activeSection === "about") {
      setCardsSidebarWidth(0);
    } else if (!isCardSidebarVisible) {
      // Only set to 300 if it was previously hidden
      setCardsSidebarWidth(300);
    }
    const timer = setTimeout(() => setIsAnimating(false), 300);
    return () => clearTimeout(timer);
  }, [activeSection, isCardSidebarVisible]);

  const handleCardClick = (item) => {
    switch (activeSection) {
      case "about":
        dispatch(setActiveAbout(item));
        break;
      case "education":
        dispatch(setActiveEducation(item));
        break;
      case "work experience":
        dispatch(setActiveWorkExperience(item));
        break;
      case "project experience":
        dispatch(setActiveProjectExperience(item));
        break;
    }
  };

  const renderSidebarCards = () => {
    switch (activeSection) {
      case "education":
        return <EducationSidebarCard onClick={handleCardClick} />;
      case "work experience":
        return <WorkExperienceSidebarCard onClick={handleCardClick} />;
      case "project experience":
        return <ProjectExperienceSidebarCard onClick={handleCardClick} />;
      default:
        return null;
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      setUser(null);
      navigate("/admin/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-screen admin-sidebar">
      <div
        ref={mainSidebarRef}
        className="bg-gray-900 p-6 shadow-lg relative flex flex-col"
        style={{
          width: `${mainSidebarWidth}px`,
        }}
      >
        {user && (
          <p className="text-white mb-4 font-semibold">Hello {user.name}</p>
        )}
        {["About", "Education", "Work Experience", "Project Experience"].map(
          (section) => (
            <button
              key={section}
              className={`w-full py-2 px-4 text-left rounded mb-2 transition-colors ${
                activeSection === section.toLowerCase()
                  ? "bg-blue-700 text-white"
                  : "text-gray-300 hover:bg-gray-800"
              }`}
              onClick={() => {
                dispatch(setActiveSection(section.toLowerCase()));
              }}
            >
              {section}
            </button>
          )
        )}
        <div className="flex-grow"></div>
        <button
          onClick={handleLogout}
          className="w-full bg-red-500 text-white px-4 py-2 rounded mt-4"
        >
          Logout
        </button>
        <div
          className="absolute top-0 right-0 w-1 h-full cursor-ew-resize bg-gray-700 hover:bg-blue-500 transition-colors"
          onMouseDown={() => setIsResizingMain(true)}
        />
      </div>

      <div
        ref={cardsSidebarRef}
        className={`bg-gray-800 p-4 shadow-lg relative overflow-y-auto ${
          isAnimating ? "transition-all duration-300 ease-in-out" : ""
        } ${isCardSidebarVisible ? "opacity-100" : "opacity-0"}`}
        style={{
          width: `${cardsSidebarWidth}px`,
          transition: isAnimating
            ? "width 0.3s ease, opacity 0.3s ease"
            : "none",
        }}
      >
        {isCardSidebarVisible && (
          <>
            <h2 className="text-xl font-bold mb-4 text-white">
              {activeSection.charAt(0).toUpperCase() + activeSection.slice(1)}
            </h2>

            {renderSidebarCards()}

            <div
              className="absolute top-0 right-0 w-1 h-full cursor-ew-resize bg-gray-700 hover:bg-blue-500 transition-colors"
              onMouseDown={() => setIsResizingCards(true)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AdminSidebar;
