import React, { useEffect, useState } from "react";
import { FaHome, FaBriefcase, FaGraduationCap, FaCode } from "react-icons/fa";

const MobileNavbar = ({ activeSection, setActiveSection }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Short delay to ensure proper rendering
    const timer = setTimeout(() => setIsVisible(true), 100);
    return () => clearTimeout(timer);
  }, []);

  const handleNavClick = (section) => {
    setActiveSection(section);
    window.location.hash = section;
  };

  return (
    <nav
      className={`flex justify-around items-center fixed bottom-0 left-0 right-0 h-20 bg-black w-full z-50 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      <button
        className="bg-transparent border-none text-4xl cursor-pointer p-2"
        onClick={() => handleNavClick("home")}
      >
        <FaHome color={activeSection === "home" ? "#3B82F6" : "white"} />
      </button>
      <button
        className="bg-transparent border-none text-4xl cursor-pointer p-2"
        onClick={() => handleNavClick("experiences")}
      >
        <FaBriefcase
          color={activeSection === "experiences" ? "#3B82F6" : "white"}
        />
      </button>
      <button
        className="bg-transparent border-none text-4xl cursor-pointer p-2"
        onClick={() => handleNavClick("projects")}
      >
        <FaCode color={activeSection === "projects" ? "#3B82F6" : "white"} />
      </button>
      <button
        className="bg-transparent border-none text-4xl cursor-pointer p-2"
        onClick={() => handleNavClick("education")}
      >
        <FaGraduationCap
          color={activeSection === "education" ? "#3B82F6" : "white"}
        />
      </button>
    </nav>
  );
};

export default MobileNavbar;
