import React from "react";

const DesktopWorkExperienceEntry = ({ experience }) => {
  const {
    company,
    position,
    startDate,
    endDate,
    description,
    imageUrl,
    isCurrent,
  } = experience;

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleString("default", { month: "short", year: "numeric" });
  };

  const dateString = () => {
    return `${formatDate(startDate)} - ${
      isCurrent ? "Present" : formatDate(endDate)
    }`;
  };

  return (
    <div className="bg-gray-900 rounded-xl p-3 mb-3 shadow-lg">
      <div className="flex items-center justify-between mb-1">
        <div className="flex-grow">
          <h3 className="text-lg text-white mb-1">{company}</h3>
          <p className="text-sm text-gray-300">{position}</p>
          <p className="text-xs text-gray-400">{dateString()}</p>
        </div>
        {imageUrl && (
          <img
            src={imageUrl}
            alt={company}
            className="w-16 h-16 rounded-lg ml-4 object-cover flex-shrink-0"
          />
        )}
      </div>

      {description && (
        <p className="text-xs text-gray-300 mt-2">{description}</p>
      )}
    </div>
  );
};

export default DesktopWorkExperienceEntry;
