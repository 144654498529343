import React from "react";
import { DesktopProjectEntry } from "./contentCards/desktopProjectEntry";
import { useGetProjectExperiencesQuery } from "../../api";

const DesktopProjects = () => {
  const {
    data: projects,
    isLoading,
    isError,
  } = useGetProjectExperiencesQuery();

  if (isLoading) {
    return (
      <div className="text-white text-center py-4">Loading Projects...</div>
    );
  }

  if (isError) {
    return (
      <div className="text-red-500 text-center py-4">
        Error loading projects
      </div>
    );
  }

  // Sort project experiences by endDate in descending order
  const sortedProjects = [...projects].sort((a, b) => {
    return new Date(b.endDate) - new Date(a.endDate);
  });

  return (
    <div className="text-white">
      <div
        style={{
          fontSize: "3em",
          fontWeight: "300",
          lineHeight: "1.25",
        }}
        className="py-2"
      >
        Projects
      </div>
      {sortedProjects.map((project) => (
        <DesktopProjectEntry key={project.id} project={project} />
      ))}
    </div>
  );
};

export default DesktopProjects;
