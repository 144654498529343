import React from "react";
import DesktopAboutContent from "./contentCards/desktopAboutContent";
import { FiGithub } from "react-icons/fi";
import { FaLinkedin } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { RiFileDownloadLine } from "react-icons/ri";

const DesktopAbout = () => {
  const githubUrl = process.env.REACT_APP_GITHUB_URL || "#";
  const linkedinUrl = process.env.REACT_APP_LINKEDIN_URL || "#";
  const emailAddress = process.env.REACT_APP_EMAIL || "";
  const my_name = process.env.REACT_APP_MY_NAME || "";

  return (
    <div className="text-white">
      <div className="flex items-center justify-between mb-1">
        <div>
          <div
            style={{
              fontSize: "3em",
              fontWeight: "300",
              lineHeight: "1.25",
            }}
          >
            About Me
          </div>
          <div className="flex space-x-8 mt-3">
            <a
              href={githubUrl}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="GitHub"
            >
              <FiGithub className="text-white hover:text-gray-300" size={20} />
            </a>
            <a
              href={linkedinUrl}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <FaLinkedin
                className="text-white hover:text-gray-300"
                size={20}
              />
            </a>
            <a href={`mailto:${emailAddress}`} aria-label="Email">
              <MdOutlineEmail
                className="text-white hover:text-gray-300"
                size={20}
              />
            </a>
            <a
              href="/resume.pdf"
              download={`${my_name}_Resume.pdf`}
              className="flex items-center space-x-2 text-white hover:text-gray-300"
              aria-label="Download Resume"
            >
              <RiFileDownloadLine size={24} />
            </a>
          </div>
        </div>

        <div
          className="rounded-full bg-gray-300 flex-shrink-0"
          style={{
            width: "125px",
            height: "125px",
            overflow: "hidden",
          }}
        >
          <img
            src="me.jpeg"
            alt="Profile"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      <DesktopAboutContent />
    </div>
  );
};

export default DesktopAbout;
