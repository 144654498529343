import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    activeSection: "about",
    activeAbout: null,
    activeEducation: null,
    activeWorkExperience: null,
    activeProjectExperience: null,
  },
  reducers: {
    setActiveSection: (state, action) => {
      state.activeSection = action.payload;
    },
    setActiveAbout: (state, action) => {
      state.activeAbout = action.payload;
    },
    setActiveEducation: (state, action) => {
      state.activeEducation = action.payload;
    },
    setActiveWorkExperience: (state, action) => {
      state.activeWorkExperience = action.payload;
    },
    setActiveProjectExperience: (state, action) => {
      state.activeProjectExperience = action.payload;
    },
  },
});

export const {
  setActiveSection,
  setActiveAbout,
  setActiveEducation,
  setActiveWorkExperience,
  setActiveProjectExperience,
} = adminSlice.actions;

export default adminSlice.reducer;
