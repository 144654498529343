import {
  FaReact,
  FaNodeJs,
  FaPython,
  FaJava,
  FaPhp,
  FaJs,
  FaHtml5,
  FaCss3,
  FaDatabase,
  FaAws,
  FaDocker,
  FaGitAlt,
  FaAngular,
  FaVuejs,
  FaCode,
  FaUnity,
  FaCcStripe,
  FaSpotify,
  FaRaspberryPi,
} from "react-icons/fa";
import {
  SiTypescript,
  SiMongodb,
  SiPostgresql,
  SiMysql,
  SiRedis,
  SiSpring,
  SiDjango,
  SiFlask,
  SiExpress,
  SiGraphql,
  SiXcode,
  SiOpencv,
  SiAmazondynamodb,
  SiAwslambda,
  SiAmazonapigateway,
  SiAmazoncognito,
} from "react-icons/si";
import { DiRedis } from "react-icons/di";

const technologyIcons = {
  react: FaReact,
  nodejs: FaNodeJs,
  node: FaNodeJs,
  python: FaPython,
  java: FaJava,
  php: FaPhp,
  javascript: FaJs,
  html: FaHtml5,
  css: FaCss3,
  sql: FaDatabase,
  aws: FaAws,
  docker: FaDocker,
  git: FaGitAlt,
  angular: FaAngular,
  vue: FaVuejs,
  typescript: SiTypescript,
  mongodb: SiMongodb,
  postgresql: SiPostgresql,
  mysql: SiMysql,
  redis: SiRedis,
  spring: SiSpring,
  django: SiDjango,
  flask: SiFlask,
  express: SiExpress,
  graphql: SiGraphql,
  unity: FaUnity,
  xcode: SiXcode,
  stripe: FaCcStripe,
  "spotify web api": FaSpotify,
  opencv: SiOpencv,
  "raspberry pi": FaRaspberryPi,
  dynamodb: SiAmazondynamodb,
  lambda: SiAwslambda,
  lambdas: SiAwslambda,
  "api gateway": SiAmazonapigateway,
  cognito: SiAmazoncognito,
  redis: DiRedis,
  "redis queue": DiRedis,
};

export const getTechnologyIcon = (tech) => {
  const lowerTech = tech.toLowerCase();
  return technologyIcons[lowerTech] || FaCode; // Default to code icon if not found
};
