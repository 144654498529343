import React, { useState } from "react";
import MobileNavbar from "./MobileNavbar";
import MobileHome from "./MobileHome";
import MobileExperiences from "./MobileExperiences";
import MobileEducation from "./MobileEducation";
import MobileProjects from "./MobileProjects";

const MobileContent = () => {
  const [activeSection, setActiveSection] = useState("home");

  const renderContent = () => {
    switch (activeSection) {
      case "home":
        return <MobileHome />;
      case "experiences":
        return <MobileExperiences />;
      case "education":
        return <MobileEducation />;
      case "projects":
        return <MobileProjects />;
      default:
        return <MobileHome />;
    }
  };

  return (
    <div className="relative h-full flex flex-col bg-black">
      <div className="flex-1 overflow-y-auto p-8 text-white text-lg">
        {renderContent()}
      </div>
      <MobileNavbar
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      />
    </div>
  );
};

export default MobileContent;
