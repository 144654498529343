import React from "react";
import MobileWorkExperienceEntry from "./contentCards/mobileExperienceEntry";
import { useGetWorkExperiencesQuery } from "../../api";

const MobileExperiences = () => {
  const {
    data: workExperiences,
    isLoading,
    isError,
  } = useGetWorkExperiencesQuery();

  if (isLoading) {
    return (
      <div className="text-white text-center py-4">
        Loading Work Experience...
      </div>
    );
  }

  if (isError) {
    return (
      <div className="text-red-500 text-center py-4">
        Error loading work experience
      </div>
    );
  }

  // Sort work experiences by endDate in descending order
  const sortedWorkExperiences = [...workExperiences].sort((a, b) => {
    return new Date(b.endDate) - new Date(a.endDate);
  });

  return (
    <div className="text-white">
      <div
        style={{
          fontSize: "3em",
          fontWeight: "300",
          lineHeight: "1.25",
        }}
        className="py-2"
      >
        Work Experience
      </div>
      {sortedWorkExperiences.map((exp) => (
        <MobileWorkExperienceEntry key={exp.id} experience={exp} />
      ))}
    </div>
  );
};

export default MobileExperiences;
