import React from "react";
import { useGetWorkExperiencesQuery } from "../../../api";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/dateFormatter";

const WorkExperienceSidebarCard = ({ onClick }) => {
  const {
    data: workExperiences,
    isLoading,
    isError,
  } = useGetWorkExperiencesQuery();
  const activeWorkExperience = useSelector(
    (state) => state.admin.activeWorkExperience
  );

  if (isLoading) {
    return <div>Loading work experience data...</div>;
  }

  if (isError) {
    return <div>Error loading work experience data</div>;
  }

  if (!workExperiences || workExperiences.length === 0) {
    return <div>No work experience has been added yet</div>;
  }

  // Sort work experiences by endDate in descending order
  const sortedWorkExperiences = [...workExperiences].sort((a, b) => {
    return new Date(b.endDate) - new Date(a.endDate);
  });

  return (
    <div>
      {sortedWorkExperiences.map((item) => (
        <div
          key={item.id}
          className={`flex items-center p-3 rounded mb-2 cursor-pointer ${
            item.id === activeWorkExperience?.id
              ? "bg-blue-600 hover:bg-blue-500"
              : "bg-gray-700 hover:bg-gray-600"
          }`}
          onClick={() => onClick(item)}
        >
          <div className="flex-grow mr-3">
            <h3 className="font-semibold">{item.company}</h3>
            <p className="text-sm text-gray-300">{item.position}</p>
            <p className="text-xs text-gray-400">
              {formatDate(item.startDate)} -{" "}
              {item.isCurrent ? "Present" : formatDate(item.endDate)}
            </p>
          </div>
          {item.imageUrl && (
            <img
              src={item.imageUrl}
              alt={item.company}
              className="w-20 h-20 object-cover rounded"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default WorkExperienceSidebarCard;
