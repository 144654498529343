import React from "react";
import { useGetProjectExperiencesQuery } from "../../../api";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/dateFormatter";

const ProjectExperienceSidebarCard = ({ onClick }) => {
  const {
    data: projectExperiences,
    isLoading,
    isError,
  } = useGetProjectExperiencesQuery();
  const activeProjectExperience = useSelector(
    (state) => state.admin.activeProjectExperience
  );

  if (isLoading) {
    return <div>Loading project experience data...</div>;
  }

  if (isError) {
    return <div>Error loading project experience data</div>;
  }

  if (!projectExperiences || projectExperiences.length === 0) {
    return <div>No project experience has been added yet</div>;
  }

  // Sort project experiences by endDate in descending order
  const sortedProjectExperiences = [...projectExperiences].sort((a, b) => {
    return new Date(b.endDate) - new Date(a.endDate);
  });

  return (
    <div>
      {sortedProjectExperiences.map((item) => {
        return (
          <div
            key={item.id}
            className={`flex items-center p-3 rounded mb-2 cursor-pointer ${
              item.id === activeProjectExperience?.id
                ? "bg-blue-600 hover:bg-blue-500"
                : "bg-gray-700 hover:bg-gray-600"
            }`}
            onClick={() => onClick(item)}
          >
            <div className="flex-grow mr-3">
              <h3 className="font-semibold">{item.title}</h3>
              <p className="text-sm text-gray-300">
                {item.technologies
                  .split(",")
                  .map((tech) => tech.trim())
                  .join(", ")}
              </p>
            </div>
            {item.imageUrl && (
              <img
                src={item.imageUrl}
                alt={item.title}
                className="w-20 h-20 object-cover rounded"
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ProjectExperienceSidebarCard;
