import React, { useState, useEffect } from "react";
import {
  FaUser,
  FaBriefcase,
  FaGraduationCap,
  FaLock,
  FaCode,
} from "react-icons/fa";

const DesktopNavbar = ({ activeTab, setActiveTab }) => {
  const [hoveredTab, setHoveredTab] = useState(null);
  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(() => {
    // set initial url
    setCurrentUrl(window.location.href);

    const handleHashChange = () => {
      setCurrentUrl(window.location.href);
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const tabs = [
    { id: "about", label: "About", icon: FaUser },
    { id: "experience", label: "Experience", icon: FaBriefcase },
    { id: "projects", label: "Projects", icon: FaCode },
    { id: "education", label: "Education", icon: FaGraduationCap },
  ];

  return (
    <div className="flex flex-col bg-gray-900">
      <div className="flex justify-center py-1.5 bg-gray-700">
        <div className="flex items-center bg-gray-800 rounded-full px-1.5 w-1/2 border border-gray-600">
          <FaLock className="text-[10px] text-gray-500 mr-1" />
          <div className="flex-1 flex justify-center">
            <input
              type="text"
              className="border-none bg-transparent text-white text-[9px] outline-none text-center w-full"
              value={currentUrl}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="flex bg-gray-900">
        {tabs.map((tab, index) => (
          <React.Fragment key={tab.id}>
            <button
              className={`flex-1 py-1 px-2 border-none cursor-pointer text-white text-[9px] flex items-center justify-center transition-colors duration-300 ${
                activeTab === tab.id
                  ? "bg-gray-700"
                  : hoveredTab === tab.id
                  ? "bg-gray-800"
                  : "bg-gray-900"
              }`}
              onClick={() => setActiveTab(tab.id)}
              onMouseEnter={() => setHoveredTab(tab.id)}
              onMouseLeave={() => setHoveredTab(null)}
            >
              <tab.icon className="mr-1 text-xs" />
              {tab.label}
            </button>
            {index < tabs.length - 1 && (
              <div className="w-px bg-gray-300 bg-opacity-15 self-stretch" />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default DesktopNavbar;
