import React, { useEffect, Suspense } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls, Environment, ContactShadows } from "@react-three/drei";
import { useDispatch, useSelector } from "react-redux";
import { setIsMobile } from "../slices/mobileSlice";
import { setInitialState } from "../slices/cameraSlice";
import { Model } from "./Model";
import CameraController from "./CameraController";

function ThreeDScene() {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.mobile.isMobile);

  useEffect(() => {
    const checkMobile = () => {
      const newIsMobile = window.innerWidth <= 768;
      dispatch(setIsMobile(newIsMobile));
      dispatch(setInitialState({ isMobile: newIsMobile }));
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, [dispatch]);

  return (
    <Canvas camera={{ fov: 60 }}>
      <CameraController />
      <ambientLight intensity={0.3} />
      <hemisphereLight intensity={0.5} color="#ffffff" groundColor="#444444" />
      <directionalLight
        position={[5, 5, 5]}
        intensity={0.5}
        castShadow
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
      />
      <spotLight
        position={[10, 10, 10]}
        angle={0.15}
        penumbra={1}
        intensity={0.5}
        castShadow
      />
      <pointLight position={[-10, -10, -10]} intensity={0.5} />
      <Suspense fallback={null}>
        <Model />
        <Environment preset="city" />
      </Suspense>
      <ContactShadows
        position={[0, -1.5, 0]}
        opacity={0.75}
        scale={10}
        blur={2.5}
        far={4}
      />
    </Canvas>
  );
}

export default ThreeDScene;
