import React from "react";
import { useSelector } from "react-redux";
import AdminAbout from "./AdminAbout";
import AdminEducation from "./AdminEducation";
import AdminWorkExperience from "./AdminWorkExperience";
import AdminProjectExperience from "./AdminProjectExperience";

const AdminContent = () => {
  const activeSection = useSelector((state) => state.admin.activeSection);

  const renderContent = () => {
    switch (activeSection) {
      case "about":
        return <AdminAbout />;
      case "education":
        return <AdminEducation />;
      case "work experience":
        return <AdminWorkExperience />;
      case "project experience":
        return <AdminProjectExperience />;
      default:
        return <div>Select a section</div>;
    }
  };

  return (
    <div className="flex-1 p-8 overflow-y-auto bg-gray-950">
      {renderContent()}
    </div>
  );
};

export default AdminContent;
