import React from "react";
import { getTechnologyIcon } from "../../technologyIcon";

const TechTag = ({ tech }) => {
  const Icon = getTechnologyIcon(tech);
  return (
    <span className="inline-flex items-center px-2 py-1 mr-2 mb-2 text-xs font-medium text-gray-300 bg-gray-700 rounded-full">
      <Icon className="mr-1" />
      {tech}
    </span>
  );
};

const DesktopProjectEntry = ({ project }) => {
  const {
    title,
    description,
    technologies,
    startDate,
    endDate,
    imageUrl,
    projectUrl,
    link,
  } = project;

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleString("default", { month: "short", year: "numeric" });
  };

  const dateString = `${formatDate(startDate)}${
    endDate ? ` - ${formatDate(endDate)}` : ""
  }`;

  return (
    <div className="bg-gray-900 rounded-xl p-3 pb-2 mb-3 shadow-lg">
      <div className="flex items-center justify-between mb-4">
        <div className="flex-grow">
          <h3 className="text-lg font-semibold text-white mb-1">{title}</h3>
          <p className="text-xs text-gray-400">{dateString}</p>
          {link && (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-300 text-sm"
            >
              View Here!
            </a>
          )}
        </div>
        {imageUrl && (
          <img
            src={imageUrl}
            alt={title}
            className="w-16 h-16 rounded-lg ml-4 object-cover flex-shrink-0"
          />
        )}
      </div>

      {description && (
        <p className="text-xs text-gray-300 mb-4">{description}</p>
      )}

      <div>
        {technologies.split(",").map((tech, index) => (
          <TechTag key={index} tech={tech.trim()} />
        ))}
      </div>
    </div>
  );
};

export { DesktopProjectEntry, TechTag };
