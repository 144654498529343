import React, { useRef } from "react";
import { useSelector, Provider } from "react-redux";
import { useFrame } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import MobileModel from "./mobile/MobileModel";
import DesktopModel from "./desktop/DesktopModel";
import MobileContent from "./mobile/MobileContent";
import DesktopContent from "./desktop/DesktopContent";
import store from "../store";

export function Model() {
  const isMobile = useSelector((state) => state.mobile.isMobile);
  const modelRef = useRef();

  // Make it float
  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    modelRef.current.rotation.y = Math.sin(t) / 60;
    modelRef.current.rotation.z = Math.sin(t) / 60;
  });

  const htmlProps = isMobile
    ? {
        position: [0, 0.3, 0],
        rotation: [0, 0, 0],
        scale: [1, 1, 1],
      }
    : {
        position: [0, 0.2, -1.1253],
        rotation: [0, 0, 0],
        scale: [0.15, 0.15, 0.15], // Reduced scale for desktop
      };

  const htmlStyle = {
    background: "black",
    padding: isMobile ? "10px" : "0",
    borderRadius: isMobile ? "50px" : "8px",
    color: "white",
    fontFamily: "Arial, sans-serif",
    width: isMobile ? "720px" : "850px", // Reduced width for desktop
    height: isMobile ? "1425px" : "500px", // Reduced height for desktop
    fontSize: isMobile ? "8px" : "12px", // Reduced font size for desktop
    overflow: "hidden",
    zIndex: 1,
  };

  const scrollbarStyle = `
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #2b2b2b;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #6b6b6b;
    border-radius: 4px;
    border: 2px solid #2b2b2b;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #8b8b8b;
  }
`;

  return (
    <group ref={modelRef}>
      {isMobile ? <MobileModel /> : <DesktopModel />}
      <Html transform occlude {...htmlProps} zIndexRange={[10, 0]}>
        <Provider store={store}>
          <div style={htmlStyle} className="z-10">
            <style>{scrollbarStyle}</style>
            {isMobile ? <MobileContent /> : <DesktopContent />}
          </div>
        </Provider>
      </Html>
    </group>
  );
}
