import React from "react";
import { useGetEducationsQuery } from "../../../api";
import { useSelector } from "react-redux";

const EducationSidebarCard = ({ onClick }) => {
  const { data: educations, isLoading, isError } = useGetEducationsQuery();
  const activeEducation = useSelector((state) => state.admin.activeEducation);

  if (isLoading) {
    return <div>Loading education data...</div>;
  }

  if (isError) {
    return <div>Error loading education data</div>;
  }

  if (!educations || educations.length === 0) {
    return <div>No education has been added yet</div>;
  }

  // Sort educations by endDate in descending order
  const sortedEducations = [...educations].sort((a, b) => {
    return new Date(b.endDate) - new Date(a.endDate);
  });

  return (
    <div>
      {sortedEducations.map((item) => (
        <div
          key={item.id}
          className={`flex items-center p-3 rounded mb-2 cursor-pointer ${
            item.id === activeEducation?.id
              ? "bg-blue-600 hover:bg-blue-500"
              : "bg-gray-700 hover:bg-gray-600"
          }`}
          onClick={() => onClick(item)}
        >
          <div className="flex-grow mr-3">
            <h3 className="font-semibold">{item.school}</h3>
            <p className="text-sm text-gray-300">{item.fieldOfStudy}</p>
            <p className="text-xs text-gray-400">
              {new Date(item.startDate).getFullYear()} -{" "}
              {item.isCurrent
                ? "Present"
                : new Date(item.endDate).getFullYear()}
            </p>
          </div>
          {item.imageUrl && (
            <img
              src={item.imageUrl}
              alt={item.school}
              className="w-20 h-20 object-cover rounded"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default EducationSidebarCard;
