import React from "react";
import MobileEducationEntry from "./contentCards/mobileEducationEntry";
import { useGetEducationsQuery } from "../../api";
const MobileEducation = () => {
  const { data: educations, isLoading, isError } = useGetEducationsQuery();

  if (isLoading) {
    return (
      <div className="text-white text-center py-4">Loading Education...</div>
    );
  }

  if (isError) {
    return (
      <div className="text-red-500 text-center py-4">
        Error loading education
      </div>
    );
  }

  return (
    <div className="text-white">
      <div
        style={{
          fontSize: "3em",
          fontWeight: "300",
          lineHeight: "1.25",
        }}
        className="mb-4"
      >
        Education & Training
      </div>
      {educations.map((edu) => (
        <MobileEducationEntry key={edu.id} education={edu} />
      ))}
    </div>
  );
};

export default MobileEducation;
