import React, { useState, useEffect } from "react";
import DesktopNavbar from "./DesktopNavbar";
import DesktopAbout from "./DesktopAbout";
import DesktopExperience from "./DesktopExperience";
import DesktopEducation from "./DesktopEducation";
import DesktopProjects from "./DesktopProjects";

const DesktopContent = () => {
  const [activeTab, setActiveTab] = useState("about");

  useEffect(() => {
    // Set initial activeTab based on URL hash
    const hash = window.location.hash.replace("#", "");
    if (hash === "about" || hash === "experience" || hash === "education") {
      setActiveTab(hash);
    }

    // Add event listener for hash changes
    const handleHashChange = () => {
      const newHash = window.location.hash.replace("#", "");
      if (
        newHash === "about" ||
        newHash === "experience" ||
        newHash === "education"
      ) {
        setActiveTab(newHash);
      }
    };

    window.addEventListener("hashchange", handleHashChange);

    // Cleanup
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  useEffect(() => {
    // Update URL hash when activeTab changes
    window.location.hash = activeTab;
  }, [activeTab]);

  const renderContent = () => {
    switch (activeTab) {
      case "about":
        return <DesktopAbout />;
      case "experience":
        return <DesktopExperience />;
      case "education":
        return <DesktopEducation />;
      case "projects":
        return <DesktopProjects />;
      default:
        return <DesktopAbout />;
    }
  };

  return (
    <div className="flex flex-col h-full bg-black rounded-md overflow-hidden">
      <DesktopNavbar activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="flex-1 p-3  px-44 overflow-y-auto text-white">
        {renderContent()}
      </div>
    </div>
  );
};

export default DesktopContent;
