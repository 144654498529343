import React, { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import ThreeDScene from "./ThreeDScene";

const ThreeDSceneWrapper = () => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="relative w-full h-full">
      <ThreeDScene />

      <div className="absolute top-0 right-0 p-4" style={{ zIndex: 10000000 }}>
        <button
          onClick={() => setShowInfo(!showInfo)}
          className="bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 transition-colors duration-200"
          aria-label="Toggle information"
        >
          <FaInfoCircle size={24} />
        </button>
      </div>

      <div
        className={`absolute top-16 right-4 bg-gray-800 text-white p-4 rounded-lg shadow-lg max-w-xs transition-opacity duration-300 ease-in-out ${
          showInfo ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        style={{ zIndex: 10000000 }}
      >
        <h3 className="text-lg font-semibold mb-2">Scene Information</h3>
        <p className="text-sm mb-2">
          Double click in the canvas to focus/unfocus on the screen. You can
          rotate and zoom the camera to explore. Try checking out both desktop
          and mobile version {";)"}
        </p>
        <h4 className="text-md font-semibold mt-4 mb-2">3D Model Credits:</h4>
        <ul className="text-sm list-disc list-inside">
          <li>
            Laptop model by{" "}
            <a
              href="https://sketchfab.com/3d-models/macbook-pro-2021-37763335f74b497e91906986b170b5d1"
              className="text-blue-500"
            >
              KangaroOz 3D
            </a>{" "}
            on Sketchfab
          </li>
          <li>
            Smartphone model by{" "}
            <a
              href="https://sketchfab.com/3d-models/iphone-12-pro-05dfc991665e45c68c8b7062136c0c6e"
              className="text-blue-500"
            >
              DatSketch
            </a>{" "}
            on Sketchfab
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ThreeDSceneWrapper;
