import React from "react";

const DesktopEducationEntry = ({ education }) => {
  const {
    school,
    degree,
    fieldOfStudy,
    startDate,
    endDate,
    description,
    imageUrl,
    gpa,
  } = education;

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleString("default", { year: "numeric" });
  };

  const dateGpaString = () => {
    const dateString = `${formatDate(startDate)}${
      startDate && endDate ? " - " : ""
    }${endDate ? formatDate(endDate) : startDate ? "Present" : ""}`;
    return gpa ? `${dateString}, GPA: ${gpa.toFixed(2)}` : dateString;
  };

  return (
    <div className="bg-gray-900 rounded-xl p-3 mb-3">
      <div className="flex items-center justify-between mb-1">
        <div className="flex-grow">
          <h3 className="text-lg text-white mb-0">{school}</h3>
          <p className="text-sm text-gray-300">
            {degree} {fieldOfStudy && ` in ${fieldOfStudy}`}
          </p>
          <p className="text-xs text-gray-400">{dateGpaString()}</p>
          {description && (
            <p className="text-xs text-gray-300 mt-2">{description}</p>
          )}
        </div>
        {imageUrl && (
          <img
            src={imageUrl}
            alt={school}
            className="w-20 h-20 rounded-full ml-4 object-cover flex-shrink-0"
          />
        )}
      </div>
    </div>
  );
};

export default DesktopEducationEntry;
