import React from "react";

const MobileEducationEntry = ({ education }) => {
  const {
    school,
    degree,
    fieldOfStudy,
    startDate,
    endDate,
    description,
    imageUrl,
    gpa,
  } = education;

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleString("default", { month: "short", year: "numeric" });
  };

  const dateGpaString = () => {
    const dateString = `${formatDate(startDate)}${
      startDate && endDate ? " - " : ""
    }${endDate ? formatDate(endDate) : startDate ? "Present" : ""}`;
    return gpa ? `${dateString}, GPA: ${gpa.toFixed(2)}` : dateString;
  };

  return (
    <div className="bg-gray-900 rounded-xl p-6 mb-6 shadow-lg">
      <div className="flex items-center justify-between mb-2">
        <div>
          <h3 className="text-4xl font-semibold text-white mb-1">{school}</h3>
          <p className="text-2xl text-gray-300">
            {degree} {fieldOfStudy && ` in ${fieldOfStudy}`}
          </p>
        </div>
        {imageUrl && (
          <img
            src={imageUrl}
            alt={school}
            className="w-20 h-20 rounded-full mr-4 object-cover"
          />
        )}
      </div>
      <div className="mb-3">
        <p className="text-xl text-gray-400">{dateGpaString()}</p>
      </div>
      {description && (
        <p className="text-2xl text-gray-300 mt-2">{description}</p>
      )}
    </div>
  );
};

export default MobileEducationEntry;
