import React, { useState, useEffect } from "react";
import {
  useGetAboutQuery,
  useUpdateAboutMutation,
  useAddAboutMutation,
} from "../../api";
import ReactMarkdown from "react-markdown";

const AdminAbout = () => {
  const { data: about, isLoading } = useGetAboutQuery();
  const [updateAbout] = useUpdateAboutMutation();
  const [addAbout] = useAddAboutMutation();

  const [formData, setFormData] = useState({
    content: "",
  });
  const [preview, setPreview] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      if (about) {
        await updateAbout({ id: about.id, ...formData }).unwrap();
        alert("About section updated successfully!");
      } else {
        await addAbout(formData).unwrap();
        alert("About section added successfully!");
      }
    } catch (error) {
      console.error("Error saving about section:", error);
      alert("Error saving about section. Please try again.");
    }
  };

  const togglePreview = () => {
    setPreview(!preview);
  };

  useEffect(() => {
    if (about) {
      setFormData(about);
    }
  }, [about]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="max-w-xl mx-auto p-6 bg-gray-800 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-white mb-4">About Section</h2>
      {preview ? (
        <div className="bg-gray-700 p-4 rounded mb-4 text-white markdown-preview">
          <ReactMarkdown
            components={{
              h1: ({ node, ...props }) => (
                <h1 className="text-3xl font-bold mb-4" {...props} />
              ),
              h2: ({ node, ...props }) => (
                <h2 className="text-2xl font-bold mb-3" {...props} />
              ),
              h3: ({ node, ...props }) => (
                <h3 className="text-xl font-bold mb-2" {...props} />
              ),
              p: ({ node, ...props }) => <p className="mb-4" {...props} />,
              ul: ({ node, ...props }) => (
                <ul className="list-disc list-inside mb-4" {...props} />
              ),
              ol: ({ node, ...props }) => (
                <ol className="list-decimal list-inside mb-4" {...props} />
              ),
              li: ({ node, ...props }) => <li className="mb-1" {...props} />,
              a: ({ node, ...props }) => (
                <a className="text-blue-400 hover:underline" {...props} />
              ),
            }}
          >
            {formData.content}
          </ReactMarkdown>
        </div>
      ) : (
        <textarea
          name="content"
          value={formData.content}
          onChange={handleInputChange}
          placeholder="About content (Markdown supported)"
          className="w-full p-2 mb-4 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-700 text-white h-64"
        />
      )}
      <div className="flex justify-between mb-4">
        <button
          onClick={togglePreview}
          className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded transition duration-200"
        >
          {preview ? "Edit" : "Preview"}
        </button>
        <button
          onClick={handleSave}
          className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded transition duration-200"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AdminAbout;
